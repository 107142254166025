/*::placeholder { !* Chrome, Firefox, Opera, Safari 10.1+ *!*/
/*    color: #b1b1b1;*/
/*    opacity: 1; !* Firefox *!*/
/*}*/

/*input[type="text"] {*/
/*    border-radius: 4px;*/
/*    width: 100%;*/
/*}*/


/* main2.css - novy design CPP (napr. Cestovni poj.) */
/*logo blue */
/*logo red */
/* modra dle designu */
/*
@font-face {
  font-family: "CPPSans";
  src: url('fonts/CPPSansBook-Regular.woff2') format('woff2'), url('CPPSansBook-Regular.woff') format('woff'), url('CPPSansBook-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "CPPSans";
  src: url('fonts/CPPSansBold-Regular.woff2') format('woff2'), url('CPPSansBold-Regular.woff') format('woff'), url('CPPSansBold-Regular.ttf') format('truetype');
  font-weight: bold;
}
*/
a {
    color: #0894d0;
}

body {
    font-family: CPPSans, Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.btn.fs16,
.fs16 {
    font-size: 16px;
}
.btn.fullwidth {
    width: 100%;
}
.fs14 {
    font-size: 14px;
}
.bld,
main form .formBox label.bld {
    font-weight: bold;
}
.nopad {
    padding: 0;
}
.btn-primary {
    font-size: 18px;
    line-height: 18px;
    padding: 9px 34px;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
    background-color: #005091;
    border: 2px solid #005091;
    border-radius: 4px;
}
.btn-primary:hover {
    border: 2px solid #3078b5;
    background-color: #3078b5;
}
.btn-info {
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
    background-color: #005091;
    border: 2px solid #005091;
    border-radius: 4px;
    font-size: 16px;
}
.btn-info.pdsmall {
    padding: 2px 10px;
}
.btn-info.pdmed {
    padding: 4px 12px;
}
.btn-info:hover,
.btn-info:active,
.btn-info:focus {
    color: #fff;
    border: 2px solid #3078b5;
    background-color: #3078b5;
}
.btn-danger {
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
    background-color: #e2001a;
    border: 2px solid #e2001a;
    border-radius: 0;
}
.btn-danger.pdsmall {
    padding: 2px 10px;
}
.btn-danger.pdmed {
    padding: 4px 12px;
}
.btn-secondary {
    font-size: 18px;
    line-height: 18px;
    padding: 9px 34px;
    border: 2px solid #005091;
    background-color: #fff;
    border-radius: 4px;
    color: #005091;
}
.btn-secondary:hover {
    color: #3078b5;
    border-color: #3078b5;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 0.5;
}
.btnradio a.active button {
    background-color: #009fe3;
    border: 2px solid #009fe3;
}
.btnradio a.disabled button {
    background-color: #999;
    border: 2px solid #999;
}
.btnvybrat {
    padding-left: 0;
}
.btnvybrat button {
    width: 100%;
}
.btnodebrat {
    width: 110px;
    margin-right: 20px;
}
.modal {
    text-align: center;
    padding: 0 !important;
}
.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}
.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
.imgerr {
    padding: 0px 20px 30px;
}
.mb0 {
    margin-bottom: 0 !important;
}
.mb10 {
    margin-bottom: 10px;
}
.mb20 {
    margin-bottom: 20px;
}
.mb40 {
    margin-bottom: 40px;
}
.mt30 {
    margin-top: 30px;
}
.ml15 {
    margin-left: 15px;
}
.ml20 {
    margin-left: 20px;
}
.pleft0 {
    padding-left: 0px;
}
.pright0 {
    padding-right: 0px;
}
.pleft15 {
    padding-left: 15px;
}
.taright {
    text-align: right;
}
.fullwidth {
    width: 100%;
}
.fa,
.fas {
    padding-right: 8px;
    padding-left: 8px;
}
label {
    margin-top: 9px;
}
.nejprod {
    position: relative;
    display: block;
    font-size: 14px;
    margin: -32px 10px 8px 20px;
    padding: 2px 20px 2px 20px;
    background-color: #eee;
    color: #000;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0px 4px 4px #00000040;
}
.tooltipIcon {
    /* margin-left: 10px;
    margin-bottom: 3px;*/
    cursor: pointer;
    filter: grayscale(100%);
    opacity: 0.3;
    width: 20px;
    height: 20px;
}
.checkbox .tooltipIcon {
    margin-bottom: 10px;
}
label img.tooltipIcon {
    vertical-align: top;
    margin-left: 10px;
}
.greytext {
    color: #999999;
}
.infotext {
    display: flex;
    align-items: center;
    height: 45px;
}
.d-flex-row {
    display: flex;
    text-align: left;
    flex-direction: row;
}
.flex50 {
    flex: 50%;
}
span.radio-inline {
    margin-top: 0;
    margin-left: 0;
    padding-left: 0px;
}
.valmsg-blck {
    padding: 6px 0;
    display: block;
}
.addresstostr {
    margin-left: 10px;
    font-size: 15px;
}
ul.check {
    list-style: none;
    color: initial;
    list-style-position: outside;
    margin-left: 10px;
    margin-top: 20px;
}
ul.check li {
    padding: 2px 0px;
}
ul.check li:before {
    font-family: 'Font Awesome 5 Pro';
    content: "\f00c";
    display: inline-block;
    padding-right: 10px;
    margin-left: -25px;
    vertical-align: middle;
    font-weight: 900;
}
.ruianok {
    float: right;
    color: yellowgreen;
}
header {
    padding-top: 21px;
    width: 100%;
}
header div.mobileH1,
header h1 {
    color: #004f91;
    font-size: 30px;
}
header div.mobileH1.desktopH1,
header h1.desktopH1 {
    display: none;
}
header .mobileH1 {
    text-align: center;
    margin: 20px 0;
}
header .headerInner {
    display: flex;
    justify-content: space-between;
}
header .headerInner .logo {
    height: 73px;
}
header .headerInner .phoneNumber {
    float: right;
    color: #6291be;
    font-size: 16px;
    margin-top: 12px;
}
header .headerInner .phoneNumber::before {
    content: url("data:image/svg+xml; utf8, <svg class='' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.14 30.875' id='Phone-6f73060f--sprite' width='16.14' height='30.875'><g data-name='Group 571'><path data-name='Path 156' d='M16.139 15.428v13.223a2.124 2.124 0 01-2.147 2.224H2.156A2.109 2.109 0 010 28.695V2.268A2.161 2.161 0 011.053.282 2.079 2.079 0 012.165.003h11.816a2.054 2.054 0 012.1 1.635 2.888 2.888 0 01.055.626q.005 6.582.003 13.164zM14.021 25.95V3.518H2.129V25.95zM8.083 1.4H5.626a.358.358 0 00-.348.335.323.323 0 00.3.351.98.98 0 00.195.015h4.624a.471.471 0 00.247-.038c.089-.06.2-.158.21-.248a.491.491 0 00-.135-.334.44.44 0 00-.29-.079c-.786-.005-1.566-.003-2.347-.003zm0 25.97a1.032 1.032 0 00-1.053 1.037 1.048 1.048 0 101.053-1.037z' fill='%238dbcef'></path></g></svg>");
    display: inline-block;
    vertical-align: middle;
    margin-right: 13px;
}
.navigation {
    box-shadow: inset 0 8px 10px #eee;
    background-color: #f8f8f8;
    /* .navigation - mobile */
}
.navigation .navbgline {
    background-color: #ffffff10;
    height: 2px;
    top: 30px;
    margin: 0 40px;
    position: relative;
    z-index: 0;
}
.navigation nav {
    background-color: #005091;
    border-radius: 0 0 10px 10px;
}
.navigation nav ul {
    padding: 0 40px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
.navigation nav ul::after,
.navigation nav ul::before {
    display: none;
}
.navigation nav ul li {
    counter-increment: item;
    flex-basis: auto;
    margin-right: 30px;
    width: 100%;
}
.navigation nav ul li a {
    text-align: center;
    color: #3078b5;
    font-size: 17px;
    font-weight: bold;
    padding: 30px 5px 18px;
}
.navigation nav ul li a.active {
    color: white;
}
.navigation nav ul li a:active,
.navigation nav ul li a:focus,
.navigation nav ul li a:hover {
    background-color: transparent;
}
.navigation nav ul li a:first-child {
    padding-left: 0;
}
.navigation nav ul li:last-child a::after {
    content: none;
}
.navigation nav ul li:last-of-type {
    margin-right: 0px;
}
.navigation nav ul li a::before {
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #2f77b5;
    color: #015091;
    line-height: 1.7;
    border-radius: 100%;
    vertical-align: middle;
    padding-top: 0px;
    text-align: center;
    position: relative;
    top: -10px;
    margin: auto auto 10px;
}
.navigation nav ul li a.current::before {
    display: block;
    content: counter(item);
    width: 27px;
    height: 27px;
    background-color: #fff;
    color: #005091;
    padding-top: 0px;
    top: -20px;
    margin: auto auto -7px;
    z-index: 2;
}
.navigation nav ul li a.current::after {
    display: block;
    content: "";
    position: absolute;
    background: linear-gradient(90deg, #ffffff00, 35%, #ffffff90 50%, 65%, #ffffff00);
    height: 2px;
    width: 100%;
    top: 24px;
    left: 0px;
    z-index: 1;
}
@media (max-width: 767px) {
    .navigation nav {
        padding: 11px 20px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
    }
    .navigation nav ul li {
        margin-right: 5px;
    }
    .navigation nav ul li a {
        font-size: 14px;
        padding: 20px 5px;
    }
    .navigation nav ul li a.current::before {
        width: 22px;
        height: 22px;
        top: -18px;
        margin: auto auto -3px;
        z-index: 2;
    }
    .navigation nav ul li a.current::after {
        top: 14px;
    }
    .navigation .navbgline {
        top: 26px;
        margin: 0;
    }
}
main {
    flex: 1 0 auto;
    padding-top: 29px;
    padding-bottom: 143px;
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    background-position: center top;
}
main .banner {
    background-color: #f4f5f8;
    color: #005091;
    padding: 20px;
    margin-bottom: 35px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 4px 10px #ccc;
}
main .banner .bannerHeading {
    font-size: 36px;
    font-weight: bold;
    float: left;
    margin-right: 30px;
}
main .banner .bannerHeading .number {
    font-size: 46px;
}
main .banner .banner-content {
    padding: 20px;
}
main .banner a {
    text-decoration: underline;
    color: #005091;
}
main .banner p {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    padding: 12px 0;
}
main .banner button.close img {
    width: 12px;
}
main h2 {
    color: #009fe3;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: -0.9px;
    line-height: 38px;
    margin-bottom: 50px;
    text-align: center;
}
main form .formBox {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px #ccc;
    margin-bottom: 30px;
    padding: 30px 0 30px;
}
main form .formBox.step1 {
    padding: 15px 0 8px;
}
main form .formBox.radioBoxes {
    /*background-image: url("../img/formBoxBg.png");*/
    background-repeat: no-repeat;
    background-position: center top;
}
main form .formBox.radioBoxes .checkbox {
    width: 220px;
    text-align: left;
    margin: 0 auto;
}
main form .formBox.radioBoxes .checkbox input[type="checkbox"] {
    margin-top: 6px;
}
main form .formBox .has-error label {
    color: red !important;
}
main form .formBox .has-error input {
    border: 1px solid red !important;
}
main form .formBox.inmodal {
    background-image: none;
    padding-bottom: 10px;
}
main form .formBox .formBoxContent {
    margin: 0 20px;
}
main form .formBox .formBoxContent.additionalPadding p {
    color: #000000;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 22px;
}
main form .formBox .formBoxContent .row.staticInputs {
    margin-bottom: 16px;
}
main form .formBox .formBoxContent .row.staticInputs .form-group {
    margin-bottom: 0;
}
main form .formBox .formBoxContent.summary .row {
    padding-bottom: 8px;
}
main form .formBox .formBoxContent.summary .row .tit {
    color: #005091;
}
main form .formBox .formBoxContent.summary .row .cena {
    text-align: right;
    color: #005091;
}
main form .formBox .formBoxContent.summary .row .cena h3 {
    text-align: right;
    font-weight: bold;
}
main form .formBox .formBoxContent.summary .row .cena2 h3 {
    text-align: right;
    font-weight: bold;
    margin: 0;
    color: #666;
}
@media (max-width: 767px) {
    main form .formBox .formBoxContent.summary .row h3 {
        font-size: 19px;
    }
}
main form .formBox .formBoxContent.summary .row.celk {
    font-weight: bold;
    font-size: 19px;
}
main form .formBox .formBoxContent.summary .row .tucne {
    font-weight: bold;
}
main form .formBox .formBoxContent.summary .row .netucne {
    font-weight: normal;
}
main form .formBox .formBoxContent.summary .row .netucne-normal {
    font-weight: normal;
    font-size: 16px;
}
main form .formBox .formBoxContent.summary .header h3 {
    margin: 0 0 15px 0;
}
main form .formBox .formBoxContent .dwnldlink1 {
    font-weight: normal;
    font-size: 16px;
}
main form .formBox .formBoxContent .zobrvsechny {
    margin-left: 55px;
    margin-top: 10px;
}
main form .formBox .formBoxContent .pojosoba {
    padding: 30px 40px 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    /*background-color: #f7f7f7;*/
    transition: visibility 1s, opacity 1s;
}
main form .formBox .formBoxContent .pojosoba p {
    font-size: 14px;
}
main form .formBox .formBoxContent .pojosoba .tit {
    font-weight: normal;
    font-size: 18px;
    color: #0894d0;
}
@media (max-width: 767px) {
    main form .formBox .formBoxContent .pojosoba {
        padding: 10px 15px;
    }
}
main form .formBox .formBoxContent .collapsebox {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    background-color: #eee;
}
@media (max-width: 767px) {
    main form .formBox .formBoxContent .collapsebox {
        padding: 10px;
    }
    main form .formBox .formBoxContent .collapsebox .row h3 {
        font-size: 17px;
    }
}
main form .formBox .formBoxContent .collapsebox > .row {
    padding-bottom: 0px;
    margin-right: 5px;
}
main form .formBox .formBoxContent .collapsebox h3 {
    font-size: 19px;
}
main form .formBox .formBoxContent .collapsebox .collapsecontent {
    margin-top: 15px;
}
main form .formBox .formBoxContent .collapsebox .collapsecontent .text-right-on-sm {
    margin-left: 0px;
}
main form .formBox .formBoxContent .collapsebox p {
    font-size: 14px;
}
main form .formBox .formBoxContent .collapsebox .tit {
    font-weight: normal;
    font-size: 18px;
    color: #0894d0;
}
main form .formBox .formBoxContent .collapsebox .text-right-on-sm {
    margin-left: 46px;
}
@media (min-width: 768px) {
    main form .formBox .formBoxContent .collapsebox .text-right-on-sm {
        text-align: right;
        margin-left: 0px;
    }
}
main form .formBox .collapsediv .collapsecontent {
    margin-top: 20px;
}
main form .formBox .form-control-static {
    color: #004f91;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}
main form .formBox .agreementCheckboxes {
    margin-top: 8px;
}
main form .formBox #differentAddress .inner,
main form .formBox #mailingAddress .inner {
    padding-top: 20px;
}
main form .formBox a.collapsed:focus {
    outline: none;
    text-decoration: none;
}
main form .formBox h3 {
    color: #005091;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 0;
    margin-left: 20px;
    text-align: left;
}
main form .formBox h3.greytext {
    color: #666;
}
main form .formBox h3.paymentFrequency {
    margin-top: 8px;
    margin-bottom: 30px;
}
main form .formBox h3.insurancePeriod {
    margin-top: 35px;
}
main form .formBox h3.assistanceService.no-info::after,
main form .formBox h3.buildingInsurance.no-info::after,
main form .formBox h3.homeInsurance.no-info::after,
main form .formBox h3.paymentFrequency.no-info::after,
main form .formBox h3.responsibilityInsurance.no-info::after {
    content: none;
}
main form .formBox h3.rollable {
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0px;
}
main form .formBox h3.rollable::before {
    display: inline-block;
    content: "\f107";
    transform: rotate(0deg);
    vertical-align: middle;
    margin-right: 16px;
    transition: 500ms;
    position: relative;
    top: -1px;
    font-family: "Font Awesome 6 Free";
}
main form .formBox .collapsed h3::before {
    transform: rotate(-90deg);
}
main form .formBox a.ah3:hover,
main form .formBox a.ah3:focus {
    text-decoration: none;
}
main form .formBox h4.title1 {
    padding-left: 15px;
    color: #0894d0;
}
main form .formBox p {
    margin: 0;
}
main form .formBox p.zarovkaInfo,
main form .formBox p.zarovkaInfoText {
    color: #005091;
}
main form .formBox p.zarovkaInfo::before {
    display: inline-block;
    /*content: url("../img/Ikona_zarovka_modra.png");*/
    vertical-align: middle;
    margin-right: 16px;
    float: left;
    margin: 8px 20px 8px 0;
}
main form .formBox p.pdficon::before {
    display: inline-block;
    content: url("../images/Ikona_pdf.png");
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    vertical-align: middle;
    margin-right: 20px;
    margin-left: -35px;
}
main form .formBox p.pdficon {
    margin-left: 5px;
}
main form .formBox p.lbl {
    margin-top: 9px;
    margin-bottom: 9px;
}
main form .formBox p.info-zeme {
    font-size: 90%;
    margin-left: 20px;
    margin-bottom: 10px;
}
main form .formBox p.info-zeme::before {
    font-family: 'Font Awesome 5 Pro';
    content: "\f30f";
    display: inline-block;
    padding-right: 10px;
    margin-left: -25px;
    vertical-align: middle;
    font-weight: 900;
}
main form .formBox p.info-zeme.info-cena {
    margin-left: 25px;
    color: #005091;
    font-weight: normal;
    font-size: 14px;
}
main form .formBox .variants h4 {
    color: #0894d0;
}
main form .formBox .variants h4.hav {
    color: #000000;
}
main form .formBox .variants p {
    font-size: 16px;
    padding-left: 15px;
    line-height: 160%;
}
main form .formBox .variants p.subtit {
    padding: 5px 20px;
}
main form .formBox .variants .pricepov {
    font-weight: bold;
}
main form .formBox input[type="text"], main form .formBox input[type="email"], main form .formBox input[type="tel"] {
    height: 40px;
    border: 1px solid rgba(33, 88, 152, 0.45);
    color: #000000;
    font-size: 16px;
}
main form .formBox .input-group-addon {
    border: 1px solid rgba(33, 88, 152, 0.45);
    border-left: none;
    color: #000000;
    font-size: 16px;
    width: 55px;
}
main form .formBox .input-group-addon.h40 {
    height: 40px;
}
main form .formBox input[type="radio"] {
    margin-left: -28px;
    width: 18px;
    height: 18px;
    margin-top: 2px;
}
main form .formBox select.myselect {
    border-radius: 0;
    border: 1px solid rgba(33, 88, 152, 0.45);
    color: #000000;
    font-size: 16px;
    height: 40px;
    padding: 6px 12px;
    width: 100%;
}
main form .formBox .form-inline,
main form .formBox .tableHeading,
main form .formBox .units,
main form .formBox label {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
}
main form .formBox .tableHeading {
    line-height: 37px;
}
main form .formBox .currencyInputWrapper {
    flex-direction: column;
}
main form .formBox .currencyInputWrapper label {
    white-space: nowrap;
}
main form .formBox .currencyInputWrapper label.v2 {
    margin-top: 3px;
}
main form .formBox .currencyInputWrapper > * {
    margin-right: 12px;
}
main form .formBox .offersInputWrapper {
    flex-direction: column;
}
main form .formBox .offersInputWrapper label.v2 {
    margin-top: 3px;
}
main form .formBox .unitsInput {
    display: flex;
    justify-content: space-between;
}
main form .formBox .unitsInput input {
    width: calc(100% - 30px);
}
main form .formBox .unitsInput .units {
    line-height: 40px;
}
main form .formBox .greyBoxed,
main form .formBox .noBox {
    background-color: #dbe5ec;
    padding: 15px 23px;
    margin-bottom: 16px;
}
main form .formBox .greyBoxed label,
main form .formBox .noBox label {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
}
main form .formBox .greyBoxed.active {
    background-color: #ffffff;
    box-shadow: 2px 4px 16px #0894d025;
}
main form .formBox.graybg {
    background-color: #eee;
    border: 1px solid #ddd;
}
main form .formBox .checkbox {
    margin-top: 0;
    margin-bottom: 0;
}
main form .formBox .checkbox input[type="checkbox"] {
    margin-left: -29px;
    width: 18px;
    height: 18px;
    margin-top: 2px;
}
main form .formBox .checkbox label {
    margin-top: 0;
    padding-left: 15px !important;
}
main form .formBox .checkbox span {
    border-color: #444;
}
main form .formBox .checkbox.v0 {
    padding: 0px 15px;
}
main form .formBox .checkbox.v1 {
    line-height: 40px;
    margin-top: 5px;
}
main form .formBox .checkbox.v1 label {
    color: #23527c;
    padding-left: 20px !important;
    font-size: 25px;
    font-weight: 700;
}
main form .formBox .checkbox.v1 span {
    margin-top: 10px;
    margin-right: 15px;
    color: #23527c;
    border-color: #23527c;
}
main form .formBox .checkbox.v1 span:before {
    color: #23527c;
}
main form .formBox .checkbox.v2 {
    line-height: 40px;
}
main form .formBox .checkbox.v2 label {
    padding-left: 20px !important;
}
main form .formBox .checkbox.v2 span {
    margin-top: 10px;
}
main form .formBox .checkbox.v3 {
    padding: 15px 23px;
}
main form .formBox .checkbox.v4 {
    padding: 10px 20px 20px 14px;
}
main form .formBox .box-inner {
    padding: 10px 20px;
    overflow: auto;
}
main form .formBox #secondaryBuildingCollapsible .form-group:first-child {
    margin-top: 25px;
}
main form .formBox #secondaryBuildingCollapsible .currencyInputWrapper {
    justify-content: flex-start;
}
main form .formBox #secondaryBuildingCollapsible .currencyInputWrapper label {
    margin-top: 9px;
    padding-left: 3px;
}
main form .formBox .radioBoxesInline {
    color: #000000;
    font-size: 16px;
    padding-left: 23px;
}
main form .formBox .radioBoxesInline label {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 0;
    padding-left: 29px;
    padding-right: 40px;
}
main form .formBox .radioBoxesInline.noPadding {
    padding-left: 0;
}
main form .formBox .radioBoxesInline.noPadding label:first-child {
    padding-left: 28px;
}
main form .formBox .radioBoxesInline.noPadding label {
    padding-right: 26px;
}
main form .formBox .imgRadio {
    padding: 8px;
    margin: 0px;
    cursor: pointer;
}
main form .formBox .imgRadio .imgWrapper {
    position: relative;
    /*display: inline-block;*/
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid rgba(33, 88, 152, 0.45);
}
main form .formBox .imgRadio .imgWrapper .tooltipIcon {
    float: right;
}
main form .formBox .imgRadio .imgWrapper .dark {
    display: block;
}
main form .formBox .imgRadio .imgWrapper .light {
    display: none;
}
main form .formBox .imgRadio .imgWrapper i.fa-check {
    color: #fff;
    font-size: 20px;
}
main form .formBox .imgRadio .imgWrapper .tab1 .row {
    padding-bottom: 4px;
}
main form .formBox .imgRadio .imgWrapper .tab1 .tit {
    color: #000;
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
}
main form .formBox .imgRadio .imgWrapper.active {
    background-color: #005091;
}
main form .formBox .imgRadio .imgWrapper.active .dark {
    display: none;
}
main form .formBox .imgRadio .imgWrapper.active .light {
    display: block;
}
main form .formBox .imgRadio .imgWrapper.active .radioTitle {
    color: #fff;
}
main form .formBox .imgRadio .imgWrapper.active .tab1 .tit {
    color: #fff;
}
main form .formBox .imgRadio .imgWrapper.active .tooltipIcon {
    filter: brightness(0) invert(1);
    opacity: 1;
}
main form .formBox .imgRadio .imgWrapper:hover {
    box-shadow: 0px 4px 10px #ddd;
}
main form .formBox .imgRadio .imgWrapper:hover .radioTitle {
    text-decoration: underline;
}
main form .formBox .imgRadio .tabWrapper {
    position: relative;
    /*display: inline-block;*/
    background-color: #fff;
    padding: 0px;
    border-radius: 4px;
    border: 1px solid #ddd;
}
main form .formBox .imgRadio .tabWrapper .tooltipIcon {
    float: right;
}
main form .formBox .imgRadio .tabWrapper i.fa-check {
    color: #ffffff;
    font-size: 20px;
}
main form .formBox .imgRadio .tabWrapper .graybg i.fa-check {
    color: #eee;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row {
    margin: 0px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz {
    background-color: #fff;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 40px;
    min-height: 55px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tithead {
    text-align: left;
    border-bottom: inherit;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .cena {
    text-align: right;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tooltipIcon {
    margin-top: 3px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz label {
    color: #fff;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tithead,
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tit,
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .cena {
    padding: 15px 10px 0px 10px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .subinfo {
    font-size: 14px;
    opacity: 65%;
    font-weight: normal;
    padding: 0px 10px 15px 40px;
    margin-left: 15px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .params {
    padding: 0px 20px 5px 26px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz.graybg {
    background-color: #eee;
}
@media (max-width: 767px) {
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz {
        padding-left: 10px;
        padding-right: 10px;
    }
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tithead,
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tit,
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .cena {
        font-size: 17px;
        padding: 15px 0px 0px 0px;
    }
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .params {
        padding: 0px 0px 5px 5px;
    }
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .fa,
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .fas {
        padding-right: 4px;
        padding-left: 4px;
        font-size: 14px;
    }
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tithead span {
        margin: 0 0px 0 0px;
    }
    main form .formBox .imgRadio .tabWrapper .tab1 .row.horiz .tooltipIcon {
        margin-left: 2px;
    }
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.mlr10 {
    margin-left: 10px;
    margin-right: 10px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row.rowhead {
    background-color: #eee;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row .tithead,
main form .formBox .imgRadio .tabWrapper .tab1 .row .tit,
main form .formBox .imgRadio .tabWrapper .tab1 .row .cena {
    padding: 10px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row .tithead {
    text-align: center;
    border-radius: 4px;
    color: #005091;
    font-size: 19px;
    font-weight: bold;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row .tithead span {
    margin: 0 10px 0 6px;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row .tit,
main form .formBox .imgRadio .tabWrapper .tab1 .row .cena {
    text-align: center;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row .tit {
    color: #000;
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
    border-bottom: 1px solid #00000020;
}
main form .formBox .imgRadio .tabWrapper .tab1 .row .cena {
    color: #005091;
    font-size: 19px;
    font-weight: bold;
}
main form .formBox .imgRadio .tabWrapper.active {
    background-color: #005091;
}
main form .formBox .imgRadio .tabWrapper.active .tab1 .row,
main form .formBox .imgRadio .tabWrapper.active .tab1 .row.horiz.graybg {
    background-color: inherit;
}
main form .formBox .imgRadio .tabWrapper.active .tab1 .tit,
main form .formBox .imgRadio .tabWrapper.active .tab1 .tithead,
main form .formBox .imgRadio .tabWrapper.active .tab1 .cena,
main form .formBox .imgRadio .tabWrapper.active .tab1 .subinfo {
    color: #fff;
}
main form .formBox .imgRadio .tabWrapper.active .tab1 .tit,
main form .formBox .imgRadio .tabWrapper.active .tab1 .tithead {
    border-bottom: 1px solid #ffffff20;
}
main form .formBox .imgRadio .tabWrapper.active .tab1 .tithead {
    background-color: inherit;
}
main form .formBox .imgRadio .tabWrapper.active .btn-info {
    background-color: #eee;
    border: 2px solid #fff;
    color: #005091;
    border-radius: 4px;
}
main form .formBox .imgRadio .tabWrapper.active .btn-info:hover {
    background-color: #3078b5;
    border-color: #3078b5;
    color: white;
}
main form .formBox .imgRadio .tabWrapper.active .tooltipIcon {
    filter: brightness(0) invert(1);
    opacity: 1;
}
main form .formBox .imgRadio .tabWrapper:hover {
    box-shadow: 0px 4px 10px #ddd;
    text-decoration: none;
}
main form .formBox .imgRadio .tabWrapper:hover .tithead span {
    text-decoration: underline;
}
main form .formBox .imgRadio .tabWrapperLabels {
    cursor: initial;
}
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row {
    margin: 0px;
}
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row.mlr10 {
    margin-left: 10px;
    margin-right: 10px;
}
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row .tithead,
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row .tit,
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row .cena {
    padding: 10px;
}
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row .tit {
    color: #000;
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
    border-bottom: 1px solid #00000020;
}
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row .cena {
    color: #000;
    font-weight: bold;
}
main form .formBox .imgRadio .tabWrapperLabels .tab1 .row .tithead {
    font-size: 19px;
    font-weight: bold;
}
main form .formBox .imgRadio input[type="radio"] {
    display: block;
    margin: 16px auto auto;
}
main form .formBox .imgRadio .radioTitle {
    color: #000;
    font-size: 19px;
    font-weight: bold;
    margin-top: 23px;
    margin-bottom: 15px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
}
main form .formBox .imgRadio .radioTitle.minh50resp {
    min-height: 50px;
}
@media (max-width: 575px) {
    main form .formBox .imgRadio .radioTitle.minh50resp {
        min-height: inherit;
    }
}
main form .formBox .imgRadio .radioTitle span {
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
}
@media (max-width: 575px) {
    main form .formBox .imgRadio .radioTitle {
        text-align: left;
        margin-top: 6px;
        min-height: 45px;
    }
}
main form .formBox .table {
    overflow-x: auto;
    margin-left: 0;
    margin-bottom: 0;
    z-index: 2;
    position: relative;
}
main form .formBox .table table {
    border-collapse: collapse;
    overflow: hidden;
    width: 100%;
    min-width: 400px;
}
main form .formBox .table table thead {
    background-color: #009fe3;
    color: #fff;
}
main form .formBox .table table thead th {
    padding: 22px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}
main form .formBox .table table thead th label {
    margin-top: 0;
}
main form .formBox .table table thead th input[type="radio"] {
    margin-top: -1px;
}
main form .formBox .table table thead th span.toggle {
    padding-top: 5px;
}
main form .formBox .table table thead th:first-child {
    width: 120px;
}
main form .formBox .table table thead th.rollable {
    cursor: pointer;
}
main form .formBox .table table thead th.rollable::before {
    display: inline-block;
    /*content: url("../img/arrowDown.png");*/
    transform: rotate(0deg);
    vertical-align: middle;
    margin-right: 16px;
    background-color: red;
    width: 22px;
    height: 22px;
    background-color: #004f91;
    text-align: center;
    border-radius: 0px;
    line-height: 22px;
    transition: 500ms;
    line-height: 19px;
    position: relative;
    top: -1px;
}
main form .formBox .table table thead th.collapsed::before {
    transform: rotate(-90deg);
}
main form .formBox .table table thead th label {
    color: #fff;
}
main form .formBox .table table tbody td {
    padding: 10px;
    border-bottom: 1px solid #009fe3;
    background-color: #e9f5fd;
}
main form .formBox .table table tbody td:first-child {
    width: 120px;
}
main form .formBox .subPrice {
    background-color: #dbe5ec;
    padding: 10px 20px;
    margin-top: -4px;
    color: #009fe3;
    font-size: 16px;
    margin-bottom: 15px;
    display: inline-block;
}
main form .formBox .subPrice b {
    margin-left: 6px;
}
main form .formBox .datepickerWrapper label {
    margin-right: 10px;
}
main form .formBox .datepickerWrapper .datepicker {
    /*background-image: url("../img/datepickerIcon.png");*/
    background-repeat: no-repeat;
    background-position: center right 2px;
    margin: 8px 8px 8px 0;
    padding: 0 10px;
}
main form .formBox .formBoxContent.paymentTypes {
    padding-left: 18px;
}
main form .formBox .formBoxContent.paymentTypes .col-sm-4,
main form .formBox .formBoxContent.paymentTypes .col-sm-5 {
    padding: 5px 0;
}
main form .formBox i.iconinside {
    position: absolute;
    top: 12px;
    right: 30px;
    color: #999;
}
main form .formBox i.iconinside.err {
    color: red;
}
main form .formBox i.iconinside.ok {
    color: green;
}
main form .formBox.souhrn {
    overflow: auto;
}
main form .formBox.souhrn h3 {
    margin-bottom: 30px;
}
main form .formBox.souhrn h3.rollable {
    margin-bottom: 0px;
}
main form .formBox.souhrn h4 {
    margin-bottom: 20px;
    font-weight: bold;
}
main form .formBox.souhrn .formBoxContent.bottommargin {
    margin-bottom: 30px;
}
main form .formBox.souhrn .formBoxContent p {
    padding: 2px 0 2px;
}
main form .formBox.souhrn .formBoxContent .row.tab {
    margin-top: 5px;
    margin-bottom: 20px;
}
main form .formBox.souhrn .formBoxContent .row {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
}
main form .formBox.souhrn .formBoxContent label.check {
    font-weight: bold;
}
main form .formBox.souhrn .formBoxContent label.check::before {
    content: "\2714";
    padding: 1px 5px;
    margin-right: 20px;
    font-size: 1em;
    font-weight: bold;
    background-color: #f0f0f0;
    border: 1px solid rgba(33, 88, 152, 0.15);
    color: #666;
}
main form .formBox.souhrn .formBoxContent label {
    padding: 2px 15px 2px;
    margin: 0;
}
main form .formBox.souhrn .formBoxContent .filebox {
    font-size: 16px;
    float: left;
    width: 96%;
    padding: 2px 10px;
    margin-left: 20px;
}
main form .formBox.souhrn .formBoxContent .filebox a {
    color: #666;
}
main form .validation-summary {
    color: red;
}
main form .priceBox {
    border: 1px solid rgba(33, 88, 152, 0.15);
    background-color: #e2001a;
    padding: 10px 40px;
    margin: 30px 0 0 0;
    color: #fff;
    border-radius: 10px 10px 0 0;
    width: 500px;
    z-index: 10;
}
main form .priceBox .sumPrice {
    font-size: 16px;
    font-weight: normal;
    padding-top: 10px;
    letter-spacing: 0.7px;
}
main form .priceBox .sumPocatek {
    text-align: center;
}
main form .priceBox .text-right {
    font-size: 16px;
    text-align: center;
    padding-top: 10px;
    letter-spacing: 0.7px;
}
main form .priceBox .text-right .number {
    font-size: 17px;
    font-weight: 700;
    margin: 0;
}
main form .priceBox a.calclink {
    color: white;
}
main form .priceBox a.calclink:hover {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: inherit;
}
main form .priceBox a.calclink span {
    font-size: 14px;
}
@media (max-width: 767px) {
    main form .priceBox {
        width: 90%;
        padding: 10px 40px 10px 0px;
    }
    main form .priceBox .sumPrice {
        font-size: 16px;
        padding-top: 2px;
        margin-bottom: 0;
        text-align: center;
    }
    main form .priceBox .text-right {
        padding-top: 2px;
        margin-bottom: 0;
        text-align: center;
    }
}
main form .successBox {
    border: 1px solid rgba(32, 138, 28, 0.15);
    padding: 19px 50px;
    margin-bottom: 20px;
    color: #fff;
    background-color: #45a941;
    font-size: 21px;
    font-weight: 700;
}
main form .errorBox {
    border: 1px solid rgba(203, 6, 6, 0.15);
    padding: 19px 50px;
    margin-bottom: 20px;
    color: #fff;
    background-color: #cb0606;
    font-size: 21px;
    font-weight: 700;
}
main form .row.area {
    margin-top: 17px;
}
main form .row.narrow {
    margin-left: 40px;
    margin-right: 40px;
}
@media (max-width: 991px) {
    main form .row.narrow {
        margin-left: 0px;
        margin-right: 0px;
    }
}
main form ul {
    color: #000;
    font-size: 16px;
    padding-left: 16px;
}
main form .stepButtons button {
    width: 100%;
    white-space: normal;
    line-height: 28px;
}
main form .stepButtons button.payButton {
    margin: 20px;
    width: initial;
}
main form .stepButtons .continueButton::after {
    content: ">";
    margin-left: 12px;
}
main form .stepButtons .backButton {
    line-height: 28px;
}
main form .stepButtons .backButton::before {
    content: "<";
    margin-right: 12px;
}
footer {
    display: none;
    background-color: #4c4c4c;
    padding: 20px;
}
footer .footerInner {
    background-color: #4e4e4e;
    padding-right: 35px;
    padding-left: 35px;
    max-width: 100%;
    width: 1037px;
    text-align: center;
}
footer .footerInner .copyright {
    color: #969696;
    font-size: 11px;
    font-weight: 300;
    display: block;
    margin-top: 40px;
}
footer .footerInner .wsvrLogo {
    margin: 20px 0 20px 8px;
}
footer .footerInner .vigLogo {
    margin: 11px 15px 15px;
}
.modal-content {
    border-radius: 10px;
}
.modal-title {
    color: #005091;
    font-size: 21px;
    font-weight: bold;
    margin-top: 25px;
}
.modal-header {
    border-bottom: 0;
    padding-left: 40px;
}
@media (max-width: 767px) {
    .modal-header {
        padding-left: 10px;
    }
}
.modal-body {
    padding: 15px 40px 40px;
    color: #000;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.15px;
}
@media (max-width: 767px) {
    .modal-body {
        padding: 20px 10px;
    }
    .modal-body ul {
        margin-left: -20px;
    }
}
.close {
    opacity: 0.8;
}
.close:hover {
    opacity: 1;
}
@media (min-width: 768px) {
    main h2 {
        text-align: left;
        margin-bottom: 23px;
    }
    main h2.first::before {
        content: "1";
    }
    main h2.second::before {
        content: "2";
    }
    main h2.third::before {
        content: "3";
    }
    main h2.fourth::before {
        content: "4";
    }
    main h2.fifth::before {
        content: "5";
    }
    main h2::before {
        display: inline-block;
        width: 47px;
        height: 47px;
        margin-right: 16px;
        background-color: #009fe3;
        color: #ffffff;
        font-size: 30px;
        font-weight: 700;
        border-radius: 100%;
        text-align: center;
        padding-top: 4px;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }
    main form .stepButtons button {
        width: 100%;
    }
    main form .formBox {
        padding: 50px 30px;
    }
    main form .formBox .form-group.currencyInputWrapper {
        display: flex;
        text-align: left;
        flex-direction: row;
    }
    main form .formBox .form-group.offersInputWrapper {
        display: flex;
        text-align: left;
        flex-direction: row;
    }
    main form .formBox .formBoxContent.additionalPadding {
        padding: 0 68px;
    }
    main form .formBox h3 {
        text-align: left;
    }
    main form .formBox h3.personalInfo::before {
        display: inline-block;
        /*content: url("../img/personalInfo.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.responsibilityInsurance::before {
        display: inline-block;
        /*content: url("../img/odpovednost-small.png");*/
        vertical-align: middle;
        margin-right: 3px;
    }
    main form .formBox h3.permanentAddress::before {
        display: inline-block;
        /*content: url("../img/permanentAddressIcon.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.homeInsurance::before {
        display: inline-block;
        /*content: url("../img/homeInsuranceIcon.png");*/
        vertical-align: middle;
        margin-right: 6px;
        position: relative;
        top: -2px;
        left: -8px;
    }
    main form .formBox h3.buildingInsurance::before {
        display: inline-block;
        /*content: url("../img/buildingInsuranceIcon.png");*/
        vertical-align: middle;
        margin-right: 6px;
        position: relative;
        top: -2px;
        left: -8px;
    }
    main form .formBox h3.assistanceService::before {
        display: inline-block;
        /*content: url("../img/assistanceServiceIcon.png");*/
        vertical-align: middle;
        margin-right: 6px;
        position: relative;
        top: -2px;
        left: -8px;
    }
    main form .formBox h3.udajeOPojisteni::before {
        display: inline-block;
        /*content: url("../img/Ikona_Udaje.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.udajeOVozidle::before {
        display: inline-block;
        /*content: url("../img/Ikona_Car.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.udajeOMne::before {
        display: inline-block;
        /*content: url("../img/Ikona_Profil.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.udajePov::before {
        display: inline-block;
        /*content: url("../img/Ikona_pov2.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.udajeHavarijni::before {
        display: inline-block;
        /*content: url("../img/Ikona_hav2.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.udajeOblibene::before {
        display: inline-block;
        /*content: url("../img/Ikona_OP.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.udajeFrekvence::before {
        display: inline-block;
        /*content: url("../img/Ikona_FP.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.zvolenyRozsah::before {
        display: inline-block;
        /*content: url("../img/Ikona_sheet.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.infoPlatba::before {
        display: inline-block;
        /*content: url("../img/Ikona_credit.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox h3.spoluprace::before {
        display: inline-block;
        /*content: url("../img/Ikona_ruce.png");*/
        vertical-align: middle;
        margin-right: 16px;
    }
    main form .formBox .radioBoxesInline label {
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0;
    }
    main form .formBox .table table thead th span {
        display: inline-block;
    }
    main form .formBox .table table thead th:first-child {
        width: 250px;
    }
    main form .formBox .table table tbody td:first-child {
        width: 250px;
    }
    main form .priceBox .sumPrice,
    main form .priceBox .sumPocatek {
        text-align: left;
    }
    main form .priceBox .text-right {
        text-align: right;
    }
    footer .footerInner {
        text-align: left;
    }
    footer .footerInner .copyright {
        float: left;
    }
    footer .footerInner .wsvrLogo {
        float: right;
    }
    footer .footerInner .vigLogo {
        float: right;
        margin: 17px 15px;
    }
    .btnodebrat {
        margin-right: 60px;
    }
}
.fixmebottomalways {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: 992px) {
    form .stepButtons button {
        width: auto;
    }
    header {
        padding-top: 0px;
    }
    header .headerInner div.mobileH1,
    header .headerInner h1 {
        display: inline-block;
        margin-top: 31px;
        padding-left: 5px;
    }
    header .headerInner div.mobileH1.desktopH1,
    header .headerInner h1.desktopH1 {
        display: block;
        padding-right: 60px;
    }
    header .headerInner .phoneNumber {
        display: none;
    }
    header .headerInner img {
        margin-bottom: 19px;
        vertical-align: top;
    }
    .mobileH1 {
        display: none;
    }
    main form .formBox .table table thead th:first-child {
        width: 350px;
    }
    main form .formBox .table table tbody td:first-child {
        width: 350px;
    }
    .container {
        max-width: 932px;
    }
}
@media (min-width: 1200px) {
    main form .formBox .form-group.currencyInputWrapper > * {
        margin-right: 12px;
    }
}
@media (max-width: 992px) {
    main form .formBox .form-group.offersInputWrapper {
        display: block !important;
    }
}
.sticky-header__row {
    display: none;
}
@media (min-width: 992px) {
    .sticky-header__row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        justify-content: flex-end;
    }
}
@media (min-width: 992px) {
    .cta__container {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
@media (min-width: 992px) and (min-width: 1200px) {
    .cta__container {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
}
.cta__box {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.8125rem 1.6875rem;
    color: #fff;
    line-height: 1.125rem;
    font-size: 1.3875rem;
}
.cta__box:hover {
    text-decoration: underline;
    color: #fff;
}
.cta__box--phone {
    color: #005091;
    padding: 0.8125rem 1.6875rem 0 0.8125rem;
}
.cta__box--phone .phone {
    display: none;
}
@media (min-width: 992px) {
    .cta__box--phone {
        padding: 0.8125rem 1.6875rem;
    }
    .cta__box--phone .phone {
        margin-right: 1rem;
        display: block;
    }
    .cta__box--phone .phoneText {
        display: none;
    }
}
.cta__box--phone a {
    text-decoration: none;
}
.cta__container--links {
    display: none;
}
@media (min-width: 992px) {
    .cta__container--links {
        display: flex;
        flex-direction: row;
    }
    .cta__container--links .cta__box--mycpp {
        border-bottom-left-radius: 0.625rem;
        background-color: #005091;
    }
    .cta__container--links .cta__box--mycpp .avatar {
        margin-right: 0.5rem;
    }
    .cta__container--links .cta__box--report {
        border-bottom-right-radius: 0.625rem;
        background-color: #e2001a;
        color: #fff;
    }
    .cta__container--links .cta__box--report .lightning {
        margin-right: 0.5rem;
    }
}
@media (max-width: 992px) {
    .backButton {
        margin: 20px 0;
    }
}
@media (max-width: 767px) {
    main form .formBox .greyBoxed,
    main form .formBox .noBox {
        padding: 10px 10px;
    }
    ul.check li {
        font-size: 90%;
    }
    main form .formBox h4 {
        font-size: 17px;
    }
    main form .formBox h3 {
        font-size: 20px;
    }
    .backButton {
        width: 100%;
        margin: 10px 0;
    }
    .inmodal .form-group {
        margin-bottom: 5px;
    }
    main form .stepButtons button.payButton {
        margin: 20px;
        width: initial;
    }
}
@media (max-width: 564px) {
    main .banner {
        padding: 10px;
    }
    main .banner .bannerHeading {
        display: contents !important;
        font-size: 26px !important;
    }
    main .banner .bannerHeading .number {
        font-size: 32px;
    }
}
.dotvvm-bp-tab-control.TabControl1 {
    border-radius: 4px;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab_nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    display: flex;
    margin: 0;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ddd;
    text-align: center;
    padding: 15px 0;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab_nav-item a {
    margin: auto auto;
    color: #000000;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab_nav-item:hover {
    box-shadow: 0px 4px 10px #ddd;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab_nav-item:hover a {
    text-decoration: none;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab-control_nav {
    border-bottom: 0;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab_nav-item.dotvvm-bp-state_active {
    border: 0;
    background-color: #005091;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab_nav-item.dotvvm-bp-state_active a {
    color: #ffffff;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab-control_content {
    padding: 15px 20px;
    border-radius: 0 0 4px 4px;
    border-top: 0;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab-control_content .row {
    padding: 5px 0;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab-control_content .row span {
    font-size: 15px;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab-control_content .row .cena {
    text-align: right;
    font-weight: bold;
}
.dotvvm-bp-tab-control.TabControl1 .dotvvm-bp-tab-control_content .row .cena.blue {
    color: #005091;
}
/* z cpp.css */
.has-error a {
    color: red !important;
}
.has-error span {
    color: red !important;
}
.block {
    display: block;
}
.block-inline {
    display: inline-block;
}
.progress-overlay {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: 100000;
}
.progress-spinner {
    position: fixed;
    z-index: 100001;
    top: 50%;
    left: 50%;
    width: 240px;
    height: 100px;
    margin-left: -120px;
    margin-top: -50px;
}
.spinner2 {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 30px;
    border-radius: 5em;
    border-color: transparent transparent #0099ff transparent;
    animation: spin 1s linear infinite;
}
.spinner-mini {
    content: "";
    display: none;
    width: 0;
    height: 0;
    border: solid 8px;
    border-radius: 2em;
    border-color: #ffffff40 #ffffff40 #ffffffC0 #ffffff40;
    animation: spin 1s linear infinite;
}
.spinner-mini.dark {
    border-color: #00509120 #00509120 #005091C0 #00509120;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
#progress {
    display: none;
}
.progress-wrap {
    box-sizing: border-box;
    vertical-align: middle;
}
.progress-spinner .item {
    display: inline-block;
    padding: 0 4em;
}
.fa-remove::before {
    content: "\f00d";
}
.input-group {
    width: 100%;
}
.input-group .form-control {
    background-color: #edf1f240;
}
.input-group .input-group-btn,
.input-group .input-group-prebtn {
    display: table-cell;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    color: #3a3f51;
    text-align: center;
    background-color: #edf1f2;
    border-top: 1px solid rgba(33, 88, 152, 0.45);
    border-right: 1px solid rgba(33, 88, 152, 0.45);
    border-bottom: 1px solid rgba(33, 88, 152, 0.45);
    border-left: none;
    border-radius: 0 4px 4px 0;
    vertical-align: middle;
    width: 40px;
    height: 35px;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 0 0 #000 !important;
    -webkit-transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    z-index: 2;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-radius: 0 !important;
}

.loader-wrapper{
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background:rgba(20, 20, 20,.8);
    z-index: 998;
}
.loader {
  z-index: 999;
  animation:spin 1s infinite linear;
  border:solid 2vmin transparent;
  border-radius:50%;
  border-right-color:#09f;
  border-top-color:#09f;
  box-sizing:border-box;
  height:20vmin;
  left:calc(50% - 10vmin);
  position:fixed;
  top:calc(50% - 10vmin);
  width:20vmin;
  z-index:1;
  }

  .loader:before {
    animation:spin 2s infinite linear;
    border:solid 2vmin transparent;
    border-radius:50%;
    border-right-color:#3cf;
    border-top-color:#3cf;
    box-sizing:border-box;
    content:"";
    height:16vmin;
    left:0;
    position:absolute;
    top:0;
    width:16vmin;
  }
  .loader:after {
    animation:spin 3s infinite linear;
    border:solid 2vmin transparent;
    border-radius:50%;
    border-right-color:#6ff;
    border-top-color:#6ff;
    box-sizing:border-box;
    content:"";
    height:12vmin;
    left:2vmin;
    position:absolute;
    top:2vmin;
    width:12vmin;
  }

@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}

.lbl{
    margin: 9px 0;
}