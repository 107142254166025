$mainHighlightColor: #075fa9;

main form .formBox .inlineRadio .imgWrapper i.not-checked {
    color: white;
}
main form .formBox .inlineRadio .imgWrapper i {
    color: $mainHighlightColor;
}

//---------------
main form .formBox .inlineRadio {
    padding: 8px;
    margin: 0px;
    cursor: pointer;
}
main form .formBox .inlineRadio .imgWrapper {
    position: relative;
    /*display: inline-block;*/
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid rgba(33, 88, 152, 0.45);
}
main form .formBox .inlineRadio .imgWrapper .tooltipIcon {
    float: right;
}
//main form .formBox .inlineRadio .imgWrapper .dark {
//    display: block;
//}
//main form .formBox .inlineRadio .imgWrapper .light {
//    display: none;
//}
main form .formBox .inlineRadio .imgWrapper i.fa-check {
    color: #fff;
    font-size: 20px;
    position: absolute;
}




main form .formBox .inlineRadio .imgWrapper .tab1 .row {
    padding-bottom: 4px;
}
main form .formBox .inlineRadio .imgWrapper .tab1 .tit {
    color: #000;
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
}
main form .formBox .inlineRadio .imgWrapper.active {
    background-color: $mainHighlightColor;
}
main form .formBox .inlineRadio .imgWrapper.active .dark {
    display: none;
}
main form .formBox .inlineRadio .imgWrapper.active .light {
    display: block;
}
main form .formBox .inlineRadio .imgWrapper.active .radioTitle {
    color: #fff;
}
main form .formBox .inlineRadio .imgWrapper.active .tab1 .tit {
    color: #fff;
}
main form .formBox .inlineRadio .imgWrapper.active .tooltipIcon {
    filter: brightness(0) invert(1);
    opacity: 1;
}
main form .formBox .inlineRadio .imgWrapper:hover {
    box-shadow: 0px 4px 10px #ddd;
}
main form .formBox .inlineRadio .imgWrapper:hover .radioTitle {
    text-decoration: underline;
}
main form .formBox .inlineRadio .tabWrapper {
    position: relative;
    /*display: inline-block;*/
    background-color: #fff;
    padding: 0px;
    border-radius: 4px;
    border: 1px solid #ddd;
}
main form .formBox .inlineRadio .tabWrapper .tooltipIcon {
    float: right;
}
main form .formBox .inlineRadio .tabWrapper i.fa-check {
    color: #ffffff;
    font-size: 20px;
}
main form .formBox .inlineRadio .tabWrapper .graybg i.fa-check {
    color: #eee;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row {
    margin: 0px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz {
    background-color: #fff;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 40px;
    min-height: 55px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tithead {
    text-align: left;
    border-bottom: inherit;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .cena {
    text-align: right;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tooltipIcon {
    margin-top: 3px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz label {
    color: #fff;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tithead,
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tit,
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .cena {
    padding: 15px 10px 0px 10px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .subinfo {
    font-size: 14px;
    opacity: 65%;
    font-weight: normal;
    padding: 0px 10px 15px 40px;
    margin-left: 15px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .params {
    padding: 0px 20px 5px 26px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz.graybg {
    background-color: #eee;
}
@media (max-width: 767px) {
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz {
        padding-left: 10px;
        padding-right: 10px;
    }
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tithead,
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tit,
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .cena {
        font-size: 17px;
        padding: 15px 0px 0px 0px;
    }
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .params {
        padding: 0px 0px 5px 5px;
    }
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .fa,
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .fas {
        padding-right: 4px;
        padding-left: 4px;
        font-size: 14px;
    }
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tithead span {
        margin: 0 0px 0 0px;
    }
    main form .formBox .inlineRadio .tabWrapper .tab1 .row.horiz .tooltipIcon {
        margin-left: 2px;
    }
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.mlr10 {
    margin-left: 10px;
    margin-right: 10px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row.rowhead {
    background-color: #eee;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row .tithead,
main form .formBox .inlineRadio .tabWrapper .tab1 .row .tit,
main form .formBox .inlineRadio .tabWrapper .tab1 .row .cena {
    padding: 10px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row .tithead {
    text-align: center;
    border-radius: 4px;
    color: #005091;
    font-size: 19px;
    font-weight: bold;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row .tithead span {
    margin: 0 10px 0 6px;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row .tit,
main form .formBox .inlineRadio .tabWrapper .tab1 .row .cena {
    text-align: center;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row .tit {
    color: #000;
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
    border-bottom: 1px solid #00000020;
}
main form .formBox .inlineRadio .tabWrapper .tab1 .row .cena {
    color: #005091;
    font-size: 19px;
    font-weight: bold;
}
main form .formBox .inlineRadio .tabWrapper.active {
    background-color: #005091;
}
main form .formBox .inlineRadio .tabWrapper.active .tab1 .row,
main form .formBox .inlineRadio .tabWrapper.active .tab1 .row.horiz.graybg {
    background-color: inherit;
}
main form .formBox .inlineRadio .tabWrapper.active .tab1 .tit,
main form .formBox .inlineRadio .tabWrapper.active .tab1 .tithead,
main form .formBox .inlineRadio .tabWrapper.active .tab1 .cena,
main form .formBox .inlineRadio .tabWrapper.active .tab1 .subinfo {
    color: #fff;
}
main form .formBox .inlineRadio .tabWrapper.active .tab1 .tit,
main form .formBox .inlineRadio .tabWrapper.active .tab1 .tithead {
    border-bottom: 1px solid #ffffff20;
}
main form .formBox .inlineRadio .tabWrapper.active .tab1 .tithead {
    background-color: inherit;
}
main form .formBox .inlineRadio .tabWrapper.active .btn-info {
    background-color: #eee;
    border: 2px solid #fff;
    color: #005091;
    border-radius: 4px;
}
main form .formBox .inlineRadio .tabWrapper.active .btn-info:hover {
    background-color: #3078b5;
    border-color: #3078b5;
    color: white;
}
main form .formBox .inlineRadio .tabWrapper.active .tooltipIcon {
    filter: brightness(0) invert(1);
    opacity: 1;
}
main form .formBox .inlineRadio .tabWrapper:hover {
    box-shadow: 0px 4px 10px #ddd;
    text-decoration: none;
}
main form .formBox .inlineRadio .tabWrapper:hover .tithead span {
    text-decoration: underline;
}
main form .formBox .inlineRadio .tabWrapperLabels {
    cursor: initial;
}
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row {
    margin: 0px;
}
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row.mlr10 {
    margin-left: 10px;
    margin-right: 10px;
}
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row .tithead,
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row .tit,
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row .cena {
    padding: 10px;
}
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row .tit {
    color: #000;
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
    border-bottom: 1px solid #00000020;
}
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row .cena {
    color: #000;
    font-weight: bold;
}
main form .formBox .inlineRadio .tabWrapperLabels .tab1 .row .tithead {
    font-size: 19px;
    font-weight: bold;
}
main form .formBox .inlineRadio input[type="radio"] {
    display: block;
    margin: 16px auto auto;
}
main form .formBox .inlineRadio .radioTitle {
    color: #000;
    font-size: 17px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
}
//50 was trasfreded into 10
main form .formBox .inlineRadio .radioTitle.minh50resp {
    min-height: 10px;
}
@media (max-width: 575px) {
    main form .formBox .inlineRadio .radioTitle.minh50resp {
        min-height: inherit;
    }
}
main form .formBox .inlineRadio .radioTitle span {
    font-size: 15px;
    font-weight: normal;
    text-transform: initial;
    opacity: 65%;
}
@media (max-width: 575px) {
    main form .formBox .inlineRadio .radioTitle {
        text-align: left;
        margin-top: 6px;
        min-height: 45px;
    }
}
