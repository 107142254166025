$placeholderColor: #ff0000;

main{

    input[type="text"], input[type="email"], {
        border-radius: 4px;
        width: 100%;
    }

    .datePickerInput{
        border-radius: 4px;
        width: 100%;
        height: 40px;
        border: 1px solid rgba(33, 88, 152, 0.45);
        color: #000000;
    }
    .ant-select-selector{
        border-radius: 4px!important;
        width: 100%;
    }
}

.form-control{
    &:hover{
        border-color: #40a9ff;
        border-right-width: 1px;
    }

    &::placeholder{
        color: $placeholderColor;
        font-size: 16px;
        font-weight: 400;
    }
    &::-webkit-input-placeholder{
        color: $placeholderColor;
        font-size: 16px;
        font-weight: 400;
    }
    &:-moz-placeholder{
        color: $placeholderColor;
        font-size: 16px;
        font-weight: 400;
    }
    &::-moz-placeholder{
        color: $placeholderColor;
        font-size: 16px;
        font-weight: 400;
    }
    &:-ms-input-placeholder{
        color: $placeholderColor;
        font-size: 16px;
        font-weight: 400;
    }

    &.with-addon {
        float: left;
        width: 77% !important;
    }

}
.ant-picker-input > input{
    font-size: 16px!important;
}
